import { Injectable } from '@angular/core';
import * as AWSCognito from "amazon-cognito-identity-js";
import Amplify ,{Auth} from 'aws-amplify';
import * as jwt_decode from "jwt-decode";
//import { Auth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { environment } from './../../environments/environment';
import * as  AWS from 'aws-sdk';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UtilsService } from './utils.service';
@Injectable()
export class CognitoServiceProvider {
  constructor(private router: Router , protected SpinnerService : NgxSpinnerService,protected toastrService : ToastrService,
              private utilService: UtilsService,){}
  private cognitoUser: CognitoUser &  { challengeParam: { sms: string } } ;
  ACCESS_TOKEN;
  REFRESH_TOKEN;
  ID_TOKEN;
  POOL_DATA = {
    UserPoolId: environment.CognitoUserPoolId,
    ClientId: environment.CognitoClientId
  };

 
    Auth =  {
      region: environment.CognitoRegion,
      userPoolId: environment.CognitoUserPoolId,
      userPoolWebClientId: environment.CognitoClientId,
    }
  
    decodeToken(token = localStorage.getItem('jwt') ){
      return jwt_decode(token);
    }


    async authUser(){
      Amplify.configure({
        Auth: this.Auth
      });  
      const data = await Auth.currentAuthenticatedUser();
      return data;
    }

    adminUserDisable(userData){
      AWS.config.update({
        accessKeyId:environment.s3accessKeyId,
        secretAccessKey:environment.s3secretAccessKey,
        region:environment.s3region
    });
    const cognito = new AWS.CognitoIdentityServiceProvider();
    let params = {
      UserPoolId: environment.CognitoUserPoolId, // From Cognito dashboard 'Pool Id'
      Username: userData.phone_number,
    };
    return cognito.adminDisableUser(params)
    .promise()
    .then(data => {
      return data;
    })
    .catch(err => {
      this.toastrService.error(err.message);
    });
    }

    adminUserEnable(userData){
      AWS.config.update({
        accessKeyId:environment.s3accessKeyId,
        secretAccessKey:environment.s3secretAccessKey,
        region:environment.s3region
    });
    const cognito = new AWS.CognitoIdentityServiceProvider();
    let params = { UserPoolId: environment.CognitoUserPoolId, Username: userData.phone_number, };
    return cognito.adminEnableUser(params).promise().then(data => {
      return data;
    }).catch(err => {
      this.toastrService.error(err.message);
    });
    }

    adminDeleteUser(userData){
      AWS.config.update({
        accessKeyId:environment.s3accessKeyId,
        secretAccessKey:environment.s3secretAccessKey,
        region:environment.s3region
    });
    const cognito = new AWS.CognitoIdentityServiceProvider();
    let params = { UserPoolId: environment.CognitoUserPoolId, Username: userData.phone_number, };
    return cognito.adminDeleteUser(params).promise().then(data => {
      return data;
    }).catch(err => {
      this.toastrService.error(err.message);
    });
    }

    adminUserGlobalSignOut(userData){
      AWS.config.update({
        accessKeyId:environment.s3accessKeyId,
        secretAccessKey:environment.s3secretAccessKey,
        region:environment.s3region
    });
    const cognito = new AWS.CognitoIdentityServiceProvider();
    let params = {UserPoolId: environment.CognitoUserPoolId,Username: userData.phone_number,};
    return cognito.adminUserGlobalSignOut(params).promise().then(data => {
      return data;
    }).catch(err => {
      this.toastrService.error(err.message);
    });
    }
    
    createCognitoUser(userData) {
      console.log(userData,'cognito data');
      //var  userData = { phone_number : '+919843394330' , name:'Shiva' , email :'appa@inovark.com' , password :'Passw123'}
      AWS.config.update({
        accessKeyId:environment.s3accessKeyId,
        secretAccessKey:environment.s3secretAccessKey,
        region:environment.s3region
    });
      const cognito = new AWS.CognitoIdentityServiceProvider();
      let params = {
        UserPoolId: environment.CognitoUserPoolId, // From Cognito dashboard 'Pool Id'
        Username: userData.phone_number,
        MessageAction: "SUPPRESS", // Do not send welcome email
        TemporaryPassword: userData.password,
        
        UserAttributes: [
          { Name: "phone_number", Value: userData.phone_number},
          { Name: "phone_number_verified",Value: "true" },
          { Name: "custom:role",Value: userData.role },
          { Name: "name",Value: userData.name  },
          { Name: "email",Value: userData.email  }
        ]
      };
      

      return cognito.adminCreateUser(params)
        .promise()
        .then(data => {
          // We created the user above, but the password is marked as temporary.
          // We need to set the password again. Initiate an auth challenge to get
          // started.
          let params = {
            AuthFlow: "ADMIN_NO_SRP_AUTH",
            ClientId: environment.CognitoClientId, // From Cognito dashboard, generated app client id
            UserPoolId: environment.CognitoUserPoolId,
            AuthParameters: {
              USERNAME: userData.phone_number,
              PASSWORD: userData.password
            }
          };
          return true;
        //  return cognito.adminInitiateAuth(params).promise(); 
        })
        .catch(err => {
            this.toastrService.error(err.message);
          });
        
    }

    adminSetUserPassword(userData){
      AWS.config.update({
        accessKeyId:environment.s3accessKeyId,
        secretAccessKey:environment.s3secretAccessKey,
        region:environment.s3region
    });
    const cognito = new AWS.CognitoIdentityServiceProvider();
    let params = {
      UserPoolId: environment.CognitoUserPoolId, // From Cognito dashboard 'Pool Id'
      Username: userData.phone_number,
      Permanent: true,
      Password : userData.password
    };
    return cognito.adminSetUserPassword(params)
    .promise()
    .then(data => {
      return data;
    })
    .catch(err => {
      this.toastrService.error(err.message);
    });
  
    }
  

    async customSignverifyUser (OTP, user ){
    Amplify.configure({
      Auth: this.Auth
    });  
    // const userData = {
    //   Attributes: values(from localStorage);
    //   }
      
    //   const cognitoUser = new CognitoUser(userData);
    return  Auth.sendCustomChallengeAnswer(this.cognitoUser, OTP);
    }

  async signIn(phoneNumber){
    Amplify.configure({
      Auth: this.Auth
    });  
    //console.log(Auth , 'signIn');
    let obj = {
      username: phoneNumber,
      password: Date.now().toString()
    }
    return  await Auth.signIn(phoneNumber).then( (res) => { return res }).catch(err=> { this.toastrService.error(err)});
    //const cognitoUser = await Auth.signIn(phoneNumber);
    return this.cognitoUser;
  }

  getCurrentSession(){
    /** To avoid angular circular reference added injector */
    //const loginService = this.injector.get(LoginService);
    Amplify.configure({ Auth: this.Auth });  
    return new Promise((resolve, reject) => {
      Auth.currentSession()
      .then(res=> { return  res })
      .catch(err=>{
        localStorage.removeItem('loginUser');
        //this.router.navigate(['/login']);
        console.log(err)
      }) 
    });
    }

  refreshToken(){

    Amplify.configure({ Auth: this.Auth });  
      return new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser().then((cognitoUser) => {
        Auth.currentSession().then((currentSession : any)=>{
          cognitoUser.refreshSession( currentSession.refreshToken , (err, session) => {
            if(err){ reject(err); }
            const { idToken, refreshToken, accessToken } = session;
            localStorage.setItem('jwt',idToken.jwtToken)
            console.log(session,'session');
            resolve(idToken.jwtToken);
          });
        })
                  
      });
  });

  /*
    Amplify.configure({
      Auth: this.Auth
    });  
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession : any  = await Auth.currentSession();
      //console.log(currentSession,'current session');
      return cognitoUser.refreshSession( currentSession.refreshToken , (err, session) => {
        if(err){
          //console.log('not able to refresh');
        }
        //console.log('New refresh session',  session);
        localStorage.setItem('jwt',session.idToken.jwtToken)
        return session;
         //const { idToken, refreshToken, accessToken } = session;
       });
    } catch (e) {
      //console.log('Unable to refresh Token', e);
    }
    */
  }
  signUp(userData) {
    return new Promise((resolved, reject) => {
      const userPoolData = new AWSCognito.CognitoUserPool(this.POOL_DATA);

      let userAttribute = [];
      userAttribute.push(new AWSCognito.CognitoUserAttribute({Name:"email",Value:userData.email }));
      userAttribute.push(new AWSCognito.CognitoUserAttribute({Name:"preferred_username",Value: userData.role}));
      userAttribute.push(new AWSCognito.CognitoUserAttribute({ Name: "phone_number", Value: userData.mobile }));
      userAttribute.push( new AWSCognito.CognitoUserAttribute({ Name: "custom:uuid", Value: userData.mobile }));
      userAttribute.push( new AWSCognito.CognitoUserAttribute({ Name: "custom:role", Value: userData.role }));
      userPoolData.signUp(userData.mobile, userData.password , userAttribute, null, function (err, result) {
        if (err) {
          
          reject(err);
        } else {
          resolved(result);
        }
      });
    });
  }
  update(userData) {
    //console.log(userData);
      let userAttribute = [];
      const userPoolData = new AWSCognito.CognitoUserPool(this.POOL_DATA);
      var authenticationDetails = new AWSCognito.AuthenticationDetails({
        Username: userData.mobile,
        Password: userData.oldPassword,
    });
      //console.log(authenticationDetails ,'authenticationDetails');
      const cognitoUser = new AWSCognito.CognitoUser({Username: userData.mobile,Pool: userPoolData});
      var attributeList = [];
      //attributeList.push(new AWSCognito.CognitoUserAttribute({ Name: "custom:scope",Value: "some new value"}));
      //attributeList.push(new AWSCognito.CognitoUserAttribute({Name: "name",Value: "some new value"}));
      //console.log(cognitoUser,'cognitoUser');
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: result => {
         //console.log(result);

         cognitoUser.changePassword(userData.oldPassword, userData.password, function(err, result) {
          if (err) {
              alert(err.message || JSON.stringify(err));
              return;
          }
          //console.log('call result: ' + result);
      });
        },
        onFailure: err => {
          //console.log(err);
        },
 
     });
     
}



  confirmUser(verificationCode, userName) {
    return new Promise((resolved, reject) => {
      const userPoolData = new AWSCognito.CognitoUserPool(this.POOL_DATA);

      const cognitoUser = new AWSCognito.CognitoUser({
        Username: userName,
        Pool: userPoolData
      });

      cognitoUser.confirmRegistration(verificationCode, true, function (err, result) {
        if (err) {
          reject(err);
          //console.log(err,"err");
        } else {
          resolved(result);
          //console.log(result , 'confirmation done');
        }
      });
    });
  }

  resendConfirmationCode(userData){
    const userPoolData = new AWSCognito.CognitoUserPool(this.POOL_DATA);
    const cognitoUser = new AWSCognito.CognitoUser({
      Username: userData.phone_number,
      Pool: userPoolData
    });
    var attributeList = [];
    var obj = { Name: "phone_number_verified", Value: "false" }
    var attribute = new AWSCognito.CognitoUserAttribute(obj);
    attributeList.push(attribute);
     
    cognitoUser.updateAttributes(attributeList, function(err, result) {
        if (err) {
            alert(err.message + 'update failed' || JSON.stringify(err));
            return;
        }
        //console.log('call result: ' + result);
    });
    // const cognitoUser = new AWSCognito.CognitoUser({
    //   Username: userData.phone_number,
    //   Pool: userPoolData
    // });
    cognitoUser.resendConfirmationCode(function(err, result) {
      if (err) {
          alert(err.message+'resend code faile' || JSON.stringify(err));
          return;
      }
      //console.log('call result: ' + result);
  });
  }
  

  authenticate(userData) {
    //console.log(userData , 'from authenticate')
    return new Promise((resolved, reject) => {
      const userPoolData = new AWSCognito.CognitoUserPool(this.POOL_DATA);
      const authDetails = new AWSCognito.AuthenticationDetails({
         Username: userData.phone_number,
         Password: userData.password
      });
      const cognitoUser = new AWSCognito.CognitoUser({
         Username: userData.phone_number,
         Pool: userPoolData
      });
      cognitoUser.authenticateUser(authDetails, {
         onSuccess: result => {
           resolved(result);
         },
         onFailure: err => {
           reject(err);
         },
  
      });
    });
 }

  

  forgotPassword(userData) {
    return new Promise((resolve, reject) => {
      const userPoolData = new AWSCognito.CognitoUserPool(this.POOL_DATA);

      const cognitoUser = new AWSCognito.CognitoUser({
        Username: userData.phone_number,
        Pool: userPoolData
      });
      cognitoUser.forgotPassword({
        onSuccess: result => {
          resolve(result);
        },
        onFailure: err => {
          this.toastrService.error(err.message);
          reject(err);
        }
      })
    })
  }


  confirmPassword(verificationCode, password, mobile) {
    return new Promise((resolve, reject) => {
      const userPoolData = new AWSCognito.CognitoUserPool(this.POOL_DATA);

      const cognitoUser = new AWSCognito.CognitoUser({
        Username: mobile,
        Pool: userPoolData
      });

      cognitoUser.confirmPassword(verificationCode, password, {
        onSuccess () {
          resolve(mobile);
        },
        onFailure: err => {
          this.toastrService.error(err.message);
          reject(err);
        }
      })
    })
  }

  // confirmPassword(verificationCode, password, email) {
  //   return new Promise((resolve, reject) => {
  //     const userPoolData = new AWSCognito.CognitoUserPool(this.POOL_DATA);

  //     const cognitoUser = new AWSCognito.CognitoUser({
  //       Username: email,
  //       Pool: userPoolData
  //     });

  //     cognitoUser.confirmPassword(verificationCode, password, {
  //       onSuccess () {
  //         //console.log('success');
  //       },
  //       onFailure: err => {
  //         reject(err);
  //       }
  //     })
  //   })
  // }

  
}