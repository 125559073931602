<nav class="navbar navbar-expand-lg navbar-absolute fixed-top">
    <div class="container-fluid">
        <div class="navbar-wrapper">
         
              <nav aria-label="breadcrumb" style="background-color: transparent !important;">
<!--                 <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="#">{{getTitle()}}</a></li>
                  <li class="breadcrumb-item"><a href="#">{{getSubTitle()}}</a></li>
                   <li class="breadcrumb-item active" aria-current="page">{{getSubTitle()}}</li>
                </ol> -->
               <app-breadcrumb></app-breadcrumb>
                
              </nav>
              
        
          
         
        </div>
        <button mat-raised-button class="navbar-toggler" type="button" (click)="sidebarToggle()">
            <span class="sr-only">Toggle navigation</span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end" id="navigation">
            <!-- <form class="navbar-form">
                <div class="input-group no-border">
                    <input type="text" value="" class="form-control" placeholder="Search...">
                    <button mat-raised-button type="submit" class="btn btn-white btn-round btn-just-icon">
                        <i class="material-icons">search</i>
                        <div class="ripple-container"></div>
                    </button>
                </div>
            </form> -->
            <ul class="navbar-nav">
                <!-- <li class="nav-item">
                    <a class="nav-link" href="javascript:void(0)">
                        <i class="material-icons">dashboard</i>
                        <p>
                            <span class="d-lg-none d-md-block">Stats</span>
                        </p>
                    </a>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link" href="javascript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="material-icons">notifications</i>
                        <span class="notification">5</span>
                        <p>
                            <span class="d-lg-none d-md-block">Some Actions</span>
                        </p>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                        <a class="dropdown-item" href="javascript:void(0)">Mike John responded to your email</a>
                        <a class="dropdown-item" href="javascript:void(0)">You have 5 new tasks</a>
                        <a class="dropdown-item" href="javascript:void(0)">You're now friend with Andrew</a>
                        <a class="dropdown-item" href="javascript:void(0)">Another Notification</a>
                        <a class="dropdown-item" href="javascript:void(0)">Another One</a>
                    </div>
                </li> -->
                <li class="nav-item">
                    <a class="nav-link" title="Logout" (click)="openModal(template)">
                        <i class="material-icons icon" data-aui-name="iconLogout" >power_settings_new</i>
                        <p>
                            <span class="d-lg-none d-md-block">Account</span>
                        </p>
                    </a>
                </li>
                
            </ul>
        </div>
    </div>
</nav>


<!-- confirmation template -->

<ng-template #template>
    <div class="modal-body text-center">
        <p>Are you sure you want to logout?</p>
        <button type="button" class="btn btn-default"  (click)="confirm()" data-aui-name="btnYes" >Yes</button>
        <button type="button" class="btn btn-danger" (click)="decline()" data-aui-name="btnNo" >No</button>
    </div>
</ng-template>

<!--

<nav class="navbar navbar-transparent navbar-absolute">
    <div class="container-fluid">
        <div class="navbar-header">
            <button mat-raised-button type="button" class="navbar-toggle" data-toggle="collapse" (click)="sidebarToggle()">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
            <a class="navbar-brand" href="javascript:void(0)">{{getTitle()}}</a>
        </div>
        <div class="collapse navbar-collapse">
            <ul class="nav navbar-nav navbar-right">
                <li>
                    <a href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown">
                        <i class="material-icons">dashboard</i>
                        <p class="hidden-lg hidden-md">Dashboard</p>
                    </a>
                </li>
                <li class="dropdown">
                    <a href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown">
                        <i class="material-icons">notifications</i>
                        <span class="notification">5</span>
                        <p class="hidden-lg hidden-md">Notifications</p>
                    </a>
                    <ul class="dropdown-menu">
                        <li><a href="javascript:void(0)">Mike John responded to your email</a></li>
                        <li><a href="javascript:void(0)">You have 5 new tasks</a></li>
                        <li><a href="javascript:void(0)">You're now friend with Andrew</a></li>
                        <li><a href="javascript:void(0)">Another Notification</a></li>
                        <li><a href="javascript:void(0)">Another One</a></li>
                    </ul>
                </li>
                <li>
                    <a href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown">
                       <i class="material-icons">person</i>
                       <p class="hidden-lg hidden-md">Profile</p>
                    </a>
                </li>
            </ul>

            <form class="navbar-form navbar-right" role="search">
                <div class="form-group form-black is-empty">
                    <input type="text" class="form-control" placeholder="Search">
                    <span class="material-input"></span>
                </div>
                <button mat-raised-button type="submit" class="btn btn-white btn-round btn-just-icon">
                    <i class="material-icons">search</i><div class="ripple-container"></div>
                </button>
            </form>
        </div>
    </div>
</nav> -->
