import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { LoginService } from 'app/services/login.service';
import { CognitoServiceProvider } from '../services/cognito-service';
import { SessionStorageService } from '../services/session-storage.service';
import { Apollo } from 'apollo-angular';
import { GET_USER_BY_MOBILE } from './../graphql/queries/user/UserMobile';
import { UtilsService } from '../services/utils.service';
import { ToastrService } from 'ngx-toastr';
import { RoutingConstants } from '../constants/RoutingConstants';
import { take } from 'rxjs/operators';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  authError: any;

  loginForm = new FormGroup({
    mobile: new FormControl(''),
    password: new FormControl('')
  });

  constructor(
    private auths: LoginService, 
    private router: Router,
    protected  cognito : CognitoServiceProvider,
    private session: SessionStorageService,
    private apollo: Apollo,
    private utilService: UtilsService,
    protected toastrService : ToastrService

  ) { 
    this.loginForm = new FormGroup({
      mobile: new FormControl('', Validators.compose([Validators.required, Validators.pattern('0-9+]+')])),
      password: new FormControl('', Validators.compose([Validators.required]))
    });
  }

  ngOnInit(): void {
    ////console.log('login');
   
    this.auths.eventAuthError$.pipe(take(1)).subscribe(data => {
      this.authError = data;
    });
    if(this.session.isLogin){
        ////console.log('navigatory')
        var obj : any = this.session.getLoginUser();
        if ( obj.role == 'manager') {
          //this.router.navigate(['/users']); 
          this.utilService.navigateRoute(RoutingConstants.User, "Users", "root");
        } else if(obj.role == 'admin') {
          //this.router.navigate(['/albums']); 
          this.utilService.navigateRoute(RoutingConstants.Album, "Albums", "root");
        }
      }
    }
  

  login(frm): any  {
    ////console.log('phone ', frm.value.mobile);
    ////console.log('password ', frm.value.password);
    if(!frm.value.mobile.toString().startsWith("+")){
      this.toastrService.error("Country Code missing for your Mobile Number.");
      return ;
    }
    let obj = { phone_number:frm.value.mobile , password:frm.value.password};
    this.cognito.authenticate(obj).then( (res : any) => {
      ////console.log(res,'login success');
      let jwt = res.idToken.jwtToken
      let refresh = res.refreshToken.token;
      let exp = res.idToken.payload.exp;
      let auth_time = res.idToken.payload.auth_time;
      
      localStorage.setItem('jwt',jwt);
      localStorage.setItem('jwt-exp',exp);
      localStorage.setItem('jwt-authTime',auth_time);
      localStorage.setItem('refreshToken',refresh);

      this.utilService.getAllCities().pipe(take(1)).subscribe(cities => {
      });
      this.utilService.getAllRegions().pipe(take(1)).subscribe(regions => {
      });

      this.setSession(frm.value.mobile);
      
      
    }).catch((error) => {
      console.log(error);
      if(error.code == "NotAuthorizedException"){
        this.toastrService.error("Incorrect Username/Password");
      }else if(error.code == "NetworkError"){
        this.toastrService.error("Kindly check your internet connection");
      }
      else{
        this.toastrService.error("Unable to login at this time. Please try again or contact the administrator.");
      }
     
     ////console.log('errr no user found',error);
    });

    //this.auths.login(frm.value.email, frm.value.password);
  }

  setSession(mobile){
    ////console.log('setSession',mobile);
    this.apollo.watchQuery<any>({
      query: GET_USER_BY_MOBILE,
      fetchPolicy: 'network-only',
      variables: {
        mobile: mobile
      }
    })
    .valueChanges
    .subscribe(({ data }) => {
    if(data.user.length){
      let userInfo = data.user[0];
      this.auths.loggedIn = {
        role: userInfo.role,
        name: userInfo.name,
        id: userInfo.id,
      };
      ////console.log("set session storage after login: "+ this.auths);
      this.session.setLoginUser(this.auths.loggedIn);
      
      //this.session.getLoginUser().subscribe( loggedUser => {
        //if ( loggedUser.role == 'manager') {
        if ( userInfo.role == 'manager') {
          //this.router.navigate(['/users']); 
          this.utilService.navigateRoute(RoutingConstants.User, "Users", "root");
        } else {
          //initialize Utils service to get tags, regions and cities
          
          this.utilService.getAllTags().pipe(take(1)).subscribe(tags => {
          });
          //this.router.navigate(['/albums']); 
          this.utilService.navigateRoute(RoutingConstants.Album, "Albums", "root");
        }
      //})
    }else{

    }
    
    })
    
    
  }
  

}
