import gql from 'graphql-tag';

export const INSERT_LOG = gql`
  mutation log_report($type: String!, $event: String!, $message: String!, 
                      $pay_load: jsonb!) {
          insert_log_report ( objects: {type: $type, event: $event, message: $message, 
                        pay_load: $pay_load })  {
    returning {
          id
          type
          event
          message
          pay_load
          created_at
        }
      }
    }
`;
