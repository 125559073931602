import gql from 'graphql-tag';

export const GET_REGIONS = gql`
    query region ($status: String!) {
        region(where: {status: {_eq: $status} }) 
          {
                id
                name
                status
        }
    }
`;