import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionStorageService } from './services/session-storage.service';
import { Router } from '@angular/router';
import { CognitoServiceProvider } from './services/cognito-service';
import { RoutingConstants } from './constants/RoutingConstants';
import { UtilsService } from './services/utils.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor( protected service : SessionStorageService,protected CognitoService:CognitoServiceProvider,
    private router: Router, private utilService: UtilsService) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      if (this.service.isLogin) {
        this.CognitoService.getCurrentSession().then(ses => {
          console.log(ses,'ses');
        })
        return true;
    }
    else {
        //this.router.navigate(['/login']);
        this.utilService.navigateRoute(RoutingConstants.Login, "Login", "root");
        return false;
    }
  }
  
}
