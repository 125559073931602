<br><br><br>
<div class="body">
    <div class="container login-container">
        <div class="row">
            <div class="col-md-6">
                <img src="assets/img/icon.png" class=" img-fluid" alt="">
            </div>
            <div class="col-md-6 login-form-2" *ngIf="showForgotPasswordForm">
                <h3>Forgot Password</h3>
                <div *ngIf="authError">
                    <div class="alert alert-danger">
                        {{ authError?.message }}
                    </div>
                </div>

                <form #forgotForm="ngForm" (ngSubmit)="forgot(forgotForm)">
                    <div class="form-group">

                          <div class="input-group ">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon2">
                                  <a href="#" class="tip">   <i class="fa fa-question-circle-o" aria-hidden="true"></i>
                                    <span>Enter your mobile number including Country code.</span></a>
                                </span>
                              </div>
                            <input type="text" class="form-control" ngModel #mobile="ngModel" name="mobile" data-aui-name="inpMobileNo" placeholder="Mobile Number *" 
                        autocomplete="off" />
                          
                          </div>
                    </div>
                    <div class="form-group text-center">                        
                        <button type="submit" class="btnSubmit" [disabled]="forgotForm.invalid" data-aui-name="btnVerify" >Verify</button>
                    </div>
                   
                </form>
            </div>
            <!---- reset password form-->
            <div class="col-md-6 login-form-2"  *ngIf="!showForgotPasswordForm">
                <h3>Reset Password</h3>
                <div *ngIf="authError">
                    <div class="alert alert-danger">
                        {{ authError?.message }}
                    </div>
                </div>

                <form #resetForm="ngForm" (ngSubmit)="resetPassword(resetForm)">
                    <div class="form-group">

                          <div class="input-group ">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon2">
                                  <a href="#" class="tip">   <i class="fa fa-question-circle-o" aria-hidden="true"></i><span>Enter your OTP.</span></a>
                                </span>
                              </div>
                            <input type="text" class="form-control" ngModel #mobile="ngModel" name="otp" data-aui-name="inpOtp" placeholder="Otp Number *" 
                        autocomplete="off" />
                          
                          </div>
                          <br>
                          <div class="input-group ">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon2">
                                  <a href="#" class="tip">   <i class="fa fa-question-circle-o" aria-hidden="true"></i><span>New password</span></a>
                                </span>
                              </div>
                            <input type="password" class="form-control" ngModel #mobile="ngModel" name="password" data-aui-name="inpNewPwd" placeholder="New password *" 
                        autocomplete="off" />
                        <button mat-icon-button matSuffix (click)="showPassword(input)">
                            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </button>
                          </div>
                    </div>
                    <div class="form-group text-center">                        
                        <button type="submit" class="btnSubmit" [disabled]="resetForm.invalid" data-aui-name="btnReset">Reset</button>
                    </div>
                   
                </form>
            </div>
            
        </div>
    </div>
</div>