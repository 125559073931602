import { Component} from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { LoginService } from './services/login.service';
import { environment } from './../environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(private spinner: NgxSpinnerService, protected login:LoginService ) {
    
  }
 
  ngOnInit() {
    //console.log(environment.mode);
    setInterval( () => { this.login.getCurrentToken() }, 20000);
    /** spinner starts on init */
    this.spinner.show();
   
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 1000);
  }
}
