import gql from 'graphql-tag';

export const GET_TAGS = gql`
    query tag_aggregate ($status: String!) {
        tag_aggregate (where: {status: {_eq : $status} }, order_by: {tag_order: asc}) 
          {
            nodes 
            {
                id
                name
                status
                tag_order
            }
        }
    }
`;

export const GET_TAGS_BY_ORDER = gql`
    query tag ($status: String!) {
        tag (where: {status: {_eq : $status} }, order_by: {tag_order: asc}) 
        {
            id
            name
            status
            tag_order
        }
    }
`;