import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {
  // Variable declaration
  public getUser: any;

  constructor(
    public router: Router,
  ) { }

  // tslint:disable-next-line: member-ordering
   key_loginUser = 'loginUser';

  // Set login user stored in session storage
  setLoginUser(loginUser) {
    //sessionStorage.setItem(this.key_loginUser, JSON.stringify(loginUser));
    localStorage.setItem(this.key_loginUser, JSON.stringify(loginUser));
  }

  // Get login user from session storage
  public getLoginUser(): Observable<any> {
    this.getUser = JSON.parse(localStorage.getItem(this.key_loginUser));
    return this.getUser;
  }
  get loginId(){
    return this.getUser.id;
  }
  get isLogin() : boolean{
    let status = this.getLoginUser();
    if(status){
      return true;
    }else{
      return false;
    }
  }

  // Remove session storage
  // getLoginUserClear() {
  //   return this.afAuth.auth.signOut().then(() => {
  //     sessionStorage.removeItem('loginUser');
  //     // Navigate to login page
  //     this.router.navigate(['login']);
  //   });
  // }
}
