import { Component, OnInit } from '@angular/core';
import { SessionStorageService } from '../../services/session-storage.service';
import { UtilsService } from '../../services/utils.service';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
  { path: '/albums', title: 'Albums',  icon: 'queue_music', class: '' },
    { path: '/tracks', title: 'Tracks', icon: 'audiotrack', class: ''},
    { path: '/books', title: 'Read',  icon: 'menu_book', class: '' },
    { path: '/tags', title: 'Tags',  icon: 'turned_in_not', class: '' },
    { path: '/users', title: 'Users',  icon: 'supervised_user_circle', class: '' },
    { path: '/region', title: 'SFI Center', icon: 'compass_calibration', class: ''},
    { path: '/upload', title: 'Multi Upload', icon: 'backup', class: ''},
    { path: '/notification', title: 'Notification', icon: 'notification_important', class: ''},
    { path: '/device', title: 'Device', icon: 'important_devices', class: ''},
    { path: '/log', title: 'Log', icon: 'report', class: ''},
    
    
];

export const USERMGR_ROUTES: RouteInfo[] = [
    { path: '/users', title: 'Users',  icon: 'supervised_user_circle', class: '' },
    
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  getUser: any;

  constructor(private session: SessionStorageService,
              private utils: UtilsService) { 
    this.getUser = this.session.getLoginUser();
    //console.log('this.getUser ', this.getUser);
  }

  ngOnInit() {
    if (this.getUser.role == 'manager') {
      //display only user menu
      this.menuItems = USERMGR_ROUTES.filter(menuItem => menuItem);
      
    } else {
      //display all menus
      this.menuItems = ROUTES.filter(menuItem => menuItem);
    }
  }

  onClick(link, title) {
    this.utils.navigateRoute(link.substr(1), title, 'root'); // truncate the leading "/"
  }

  navigateToURL() {
    const routeMap = this.utils.getRoutingMap();
    console.log('finalMap=', routeMap);
    const finalMap = routeMap !== undefined && routeMap !== null && routeMap.length > 0 ? routeMap.slice(0 , 1) : '';
    console.log('finalMap=', finalMap);
    this.utils.setRoutingMap(finalMap);

  }

  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };
}
