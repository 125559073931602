
<router-outlet></router-outlet>
<ngx-spinner
bdOpacity = 0.9
bdColor = "#333"
size = "medium"
color = "#fff"
type = "ball-circus"
[fullScreen] = "true"
>
<p style="color: white" > Loading... </p>
</ngx-spinner>