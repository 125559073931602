<div class="logo">
    <a href="javascript:void(0)" class="simple-text">
        <div class="logo-img">
            <img src="/assets/img/sws logo.svg"/>
        </div>
        SWS
    </a>
</div>
<div class="sidebar-wrapper">
  <div *ngIf="isMobileMenu()">
    
  </div>
    <ul class="nav">
        <li routerLinkActive="active" *ngFor="let menuItem of menuItems" class="{{menuItem.class}} nav-item">
            <!-- <a class="nav-link" [routerLink]="[menuItem.path]"> -->
            <a class="nav-link" [routerLink]="[menuItem.path]" (click)="onClick(menuItem.path, menuItem.title)" >
                <i class="material-icons">{{menuItem.icon}}</i>
                <p>{{menuItem.title}}</p>
            </a>
        </li>
    </ul>
</div>
