import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { environment } from 'environments/environment';
import * as LOG from '../graphql/mutations/Logger';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  constructor(private apollo: Apollo,
              private http: HttpClient) { 

  }

  insertLogger(value) {
    // let variables =  {
    //       title: value.type,
    //       artiste: value.event,
    //       visibility: value.message,
    //       updated_by: value.pay_load
    //     }
    //return this.http.post('http://localhost:4200'+'/log_report', variables, {headers: {'Anonymous': ''}}); 
    return this.apollo.mutate<any>( {
      mutation: LOG.INSERT_LOG,
      variables: {
        type: value.type,
        event: value.event ? value.event : 'No event',
        message: value.message,
        pay_load: value.pay_load
      }
    });
  }
}
