import { Component, OnInit, ElementRef, TemplateRef } from '@angular/core';
//import { ROUTES } from '../sidebar/sidebar.component';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import { Router } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

declare interface PagesInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
const ROUTES: PagesInfo[] = [
    { path: '/albums', title: 'Albums',  icon: 'queue_music', class: '' },
    { path: '/tracks', title: 'Tracks', icon: 'audiotrack', class: ''},
    { path: '/books', title: 'Read',  icon: 'menu_book', class: '' },
    { path: '/tags', title: 'Tags',  icon: 'turned_in_not', class: '' },
    { path: '/users', title: 'Users',  icon: 'supervised_user_circle', class: '' },
    { path: '/region', title: 'Region', icon: 'compass_calibration', class: ''},
    { path: '/upload', title: 'Multi Upload', icon: 'backup', class: ''},
    { path: '/notification', title: 'Notification', icon: 'notification_important', class: ''},
    { path: '/album-add', title: 'Add Album',  icon: 'queue_music', class: '' },
    { path: '/track-add', title: 'Add Track', icon: 'audiotrack', class: ''},
    { path: '/books-add', title: 'Add Book',  icon: 'menu_book', class: '' },
    { path: '/tags-add', title: 'Add Tag',  icon: 'turned_in_not', class: '' },
    { path: '/users-add', title: 'Add User',  icon: 'supervised_user_circle', class: '' },
    { path: '/region-add', title: 'Add Region', icon: 'compass_calibration', class: ''},
];

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
    private listTitles: any[];
    location: Location;
      mobile_menu_visible: any = 0;
    private toggleButton: any;
    private sidebarVisible: boolean;
    modalRef: BsModalRef;
    message: string;

    constructor(location: Location,  
                private element: ElementRef, 
                private router: Router,
                private service: LoginService,
                private modalService: BsModalService) {
      this.location = location;
          this.sidebarVisible = false;
    }

    ngOnInit(){
      this.listTitles = ROUTES.filter(listTitle => listTitle);
      const navbar: HTMLElement = this.element.nativeElement;
      this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
      this.router.events.subscribe((event) => {
        this.sidebarClose();
         var $layer: any = document.getElementsByClassName('close-layer')[0];
         if ($layer) {
           $layer.remove();
           this.mobile_menu_visible = 0;
         }
     });
    }

    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const body = document.getElementsByTagName('body')[0];
        setTimeout(function(){
            toggleButton.classList.add('toggled');
        }, 500);

        body.classList.add('nav-open');

        this.sidebarVisible = true;
    };
    sidebarClose() {
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        body.classList.remove('nav-open');
    };
    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        var $toggle = document.getElementsByClassName('navbar-toggler')[0];

        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
        const body = document.getElementsByTagName('body')[0];

        if (this.mobile_menu_visible == 1) {
            // $('html').removeClass('nav-open');
            body.classList.remove('nav-open');
            if ($layer) {
                $layer.remove();
            }
            setTimeout(function() {
                $toggle.classList.remove('toggled');
            }, 400);

            this.mobile_menu_visible = 0;
        } else {
            setTimeout(function() {
                $toggle.classList.add('toggled');
            }, 430);

            var $layer = document.createElement('div');
            $layer.setAttribute('class', 'close-layer');


            if (body.querySelectorAll('.main-panel')) {
                document.getElementsByClassName('main-panel')[0].appendChild($layer);
            }else if (body.classList.contains('off-canvas-sidebar')) {
                document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
            }

            setTimeout(function() {
                $layer.classList.add('visible');
            }, 100);

            $layer.onclick = function() { //asign a function
              body.classList.remove('nav-open');
              this.mobile_menu_visible = 0;
              $layer.classList.remove('visible');
              setTimeout(function() {
                  $layer.remove();
                  $toggle.classList.remove('toggled');
              }, 400);
            }.bind(this);

            body.classList.add('nav-open');
            this.mobile_menu_visible = 1;

        }
    };

    getTitle(){
        //console.log("this.location.path = ", this.location.path());
      var titlee = this.location.prepareExternalUrl(this.location.path());
      if(titlee.charAt(0) === '#'){
          titlee = titlee.slice( 1 );
      }

      for(var item = 0; item < this.listTitles.length; item++){
          if(this.listTitles[item].path === titlee){
              return this.listTitles[item].title;
          }
      }
      return 'Dashboard';
    }

    getSubTitle(){
        //console.log("this.location.path = ", this.location.path());
      var titlee = this.location.prepareExternalUrl(this.location.path());
      if(titlee.charAt(0) === '#'){
          titlee = titlee.slice( 1 );
      }

      for(var item = 0; item < this.listTitles.length; item++){
          if(this.listTitles[item].path === titlee){
              //console.log("inside if loop=", titlee)
              return this.listTitles[item].title;
          }
      }
      return 'Dashboard';
    }

    openModal(template: TemplateRef<any>) {
        //console.log('openModal');
        this.modalRef = this.modalService.show(template, {class: 'modal-sm'});
    }

    confirm(): void {
        this.message = 'Confirmed!';
        this.modalRef.hide();
        this.service.logout();
        //console.log('User logged out Successfully!');
    }

    decline(): void {
        this.message = 'Declined!';
        this.modalRef.hide();
    }

}
