import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse
  } from '@angular/common/http';
  import { Observable, throwError, from } from 'rxjs';
  import { map, catchError, switchMap } from 'rxjs/operators';
  import {
    Router
  } from '@angular/router';
  import { LoginService } from 'app/services/login.service';
  import { Injectable } from '@angular/core';
import { LoggerService } from './logger.service';

  
  @Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private LoginService: LoginService, 
              private logService: LoggerService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //const currToken  : any  = this.LoginService.getCurrentToken();
  //var token = currToken.__zone_symbol__value ? currToken.__zone_symbol__value  :  currToken ;
  //console.log("****intercept**** = "+JSON.stringify(request));
  //console.log("****intercept11**** = "+request.body.operationName);
  //if (request.headers.get('Anonymous') !== undefined) {
  if (request.body.operationName == 'log_report') {
    //console.log(" ** anonymous **");
    let newHeaders = request.headers.delete('Anonymous');
    // newHeaders = request.headers
    //                 .set('Authorization', 'Bearer ' + this.LoginService.getCurrentToken())
    //                 .set('Accept', 'application/json')
    //                 .append('Content-Type', 'application/json');
    return from(this.LoginService.getCurrentToken())
      .pipe(
        switchMap(token => {
          newHeaders = request.headers
                    .set('Authorization', 'Bearer ' + token)
                    .set('Accept', 'application/json')
                    .append('Content-Type', 'application/json');
          const newRequest = request.clone({ headers: newHeaders });
          return next.handle(newRequest);
        })
      )
  } else {
    //console.log(" ** NOT anonymous **");
    return from(this.LoginService.getCurrentToken())
      .pipe(
        switchMap(token => {
          const headers = request.headers
                    .set('Authorization', 'Bearer ' + token)
                    .set('Accept', 'application/json')
                    .append('Content-Type', 'application/json');
                  const requestClone = request.clone({headers });
          //return next.handle(requestClone);
          return next.handle(requestClone).pipe(
            map((event: HttpEvent<any>) => {
              
              if (event instanceof HttpResponse) {
                console.log("****intercept event**** = "+JSON.stringify(event));
                let logObject = {
                  type: "Success",
                  event: JSON.stringify(request.body.query),
                  message: event.status + "(" + event.statusText+")",
                  pay_load: (event.body)
                }
                //console.log("Logger: logObject = "+JSON.stringify(logObject));
                this.logService.insertLogger(logObject).subscribe(log => {
                  //console.log("Logger: Success Inserted to log table = "+JSON.stringify(log));
                })
              }
              
              return event;
            }),
            catchError((error: HttpErrorResponse) => {
              if (error.status === 401) {
                if (error.error.success === false) {
                  
                } else {
                  
                }
              }
              console.log("Logger: Error = "+JSON.stringify(error));
              let logObject = {
                type: 'Error:'+error.status+"("+error.statusText+")",
                event: JSON.stringify(error),
                message: error.message,
                pay_load: (error)
              }
              this.logService.insertLogger(logObject).subscribe(log => {
                //console.log("Logger: Error Inserted to log table = "+JSON.stringify(log));
              })
              return throwError(error);
            }));
        })
      );
  }
  /*
  var token = this.LoginService.getLocalStorage('jwt');
     if (token) {
      request = request.clone({
        setHeaders: {
          'Authorization': `Bearer `+token
        }
      });
    }

    if (!request.headers.has('Content-Type')) {
      request = request.clone({
        setHeaders: {
          'content-type': 'application/json'
        }
      });
    }
  
    request = request.clone({
      headers: request.headers.set('Accept', 'application/json')
    });
  
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          //console.log('event--->>>', event);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          if (error.error.success === false) {
            //console.log('login failed')
          } else {
            //console.log('login redirect')
          }
        }
        return throwError(error);
      }));

      */
  }
}