import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import * as Query from './../graphql/queries/tags/Tags';
import * as CityQuery from './../graphql/queries/city/Cities';
import * as RegionQuery from './../graphql/queries/region/Regions';
import { environment } from './../../environments/environment';
import {map} from 'rxjs/operators';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  tags: Array<any>;
  cities: Array<any>;
  regions: Array<any>;
  //s3_album_url: any = "https://sws-album.s3.ap-south-1.amazonaws.com/";
  s3_album_url: any = environment.mediaUrl;//"https://swsmedia.b-cdn.net/"; 
  routeMap: any = [];

  constructor(private apollo: Apollo,
              private router: Router) { 

  }

  getCities() {
    this.getAllCities();
  }

  getAllTags() {
    this.tags = [];
    return this.apollo.watchQuery<any>({
      query: Query.GET_TAGS,
      fetchPolicy: 'network-only',
      variables: {
        status: "Active"
      }
    })
    .valueChanges
    .pipe(map( ({ data }) => {
      if (data.tag_aggregate.length !== 0) {
        this.tags = data.tag_aggregate.nodes;
        return this.tags;
      }
    }));
  }

  getAllRegions() {
    this.regions = [];
    return this.apollo.watchQuery<any>({
      query: RegionQuery.GET_REGIONS,
      fetchPolicy: 'network-only',
      variables: {
        status: "Active"
      }
    })
    .valueChanges
    .pipe(map( ({ data }) => {
      if (data.region.length !== 0) {
        this.regions = data.region;
        return this.regions;
      }
    }));
  }


  getAllCities() {
    this.cities = [];
    return this.apollo.watchQuery<any>({
      query: CityQuery.GET_CITIES,
      fetchPolicy: 'network-only',
    })
    .valueChanges
    .pipe(map( ({ data }) => {
      if (data.city.length !== 0) {
        this.cities = data.city;
        return this.cities;
      }
    }));
  }

  getBookContentFolder() {
    return this.s3_album_url+"books/content/";
  }

  getBookCoverFolder() {
    return this.s3_album_url+"books/images/";
  }

  getTrackFolder() {
    return this.s3_album_url+"audio/tracks/";
  }

  getTrackCoverFolder() {
    return this.s3_album_url+"audio/images/";
  }

  getAlbumThumbnailFolder() {
    return this.s3_album_url+"audio/images_tn/";
  }

  generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = (performance && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  getRoutingMap() {
    return JSON.parse(sessionStorage.getItem('routingURL'));
  }

  setRoutingMap(map) {
    sessionStorage.setItem('routingURL', JSON.stringify(map));
  }

  navigateRoute(url, breadCrumbName, type) {
    this.routeMap = JSON.parse(sessionStorage.getItem('routingURL'));
    console.log("type =",type);
    console.log("this.routeMap =",this.routeMap);
    if (type === 'root') {
      // clear the route map
      this.routeMap = [];
    } else { // if append
      // do not clear the routemap
    }
    const route: any = {
      name: breadCrumbName,
      url: url
    };
    this.routeMap.push(route);
    sessionStorage.setItem('routingURL', JSON.stringify(this.routeMap));
    this.router.navigate([url]);
  }

  navigateRouteWithQP(url, QP, breadCrumbName, type) {
    this.routeMap = JSON.parse(sessionStorage.getItem('routingURL'));
    console.log("type =",type);
    console.log("this.routeMap =",this.routeMap);
    if (type === 'root') {
      // clear the route map
      this.routeMap = [];
    } else { // if append
      // do not clear the routemap
    }
    const route: any = {
      name: breadCrumbName,
      url: url
    };
    this.routeMap.push(route);
    sessionStorage.setItem('routingURL', JSON.stringify(this.routeMap));
    this.router.navigate([url], QP);
  }

  navigateWithActivatedRoute(activRoute, QP, breadCrumbName, type) {
    this.routeMap = JSON.parse(sessionStorage.getItem('routingURL'));
    console.log("type =",type);
    console.log("this.routeMap =",this.routeMap);
    if (type === 'root') {
      // clear the route map
      this.routeMap = [];
    } else { // if append
      // do not clear the routemap
    }
    const route: any = {
      name: breadCrumbName,
      url: activRoute.url
    };
    
    this.routeMap.push(route);
    console.log("routemap = "+this.routeMap);
    sessionStorage.setItem('routingURL', JSON.stringify(this.routeMap));

    this.router.navigate([], {
        relativeTo: activRoute,
        queryParams: QP,
        queryParamsHandling: 'merge',
      });
  }

  navigateWithRoute(routeUrl, QP, breadCrumbName, type) {
    this.routeMap = JSON.parse(sessionStorage.getItem('routingURL'));
    console.log("type =",type);
    console.log("this.routeMap =",this.routeMap);
    if (type === 'root') {
      // clear the route map
      this.routeMap = [];
    } else { // if append
      // do not clear the routemap
    }
    const route: any = {
      name: breadCrumbName,
      url: routeUrl
    };
    
    this.routeMap.push(route);
    console.log("routemap = "+this.routeMap);
    sessionStorage.setItem('routingURL', JSON.stringify(this.routeMap));

    this.router.navigate([routeUrl], {
          queryParams: QP,
          queryParamsHandling: 'merge',
        });
  }

}
