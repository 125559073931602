import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CognitoServiceProvider } from './../services/cognito-service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { RoutingConstants } from '../constants/RoutingConstants';
import { Utils } from 'ngx-bootstrap';
import { UtilsService } from '../services/utils.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  constructor( protected cognito : CognitoServiceProvider , protected toastrService: ToastrService , private router: Router,
                private utilsService: UtilsService) { }

  showForgotPasswordForm : boolean =  false;
  hide : any = false;
  mobile : any;
  forgotForm = new FormGroup({
    mobile: new FormControl('', Validators.compose([Validators.required, Validators.pattern('0-9+]+')]))
  });

  resetForm = new FormGroup({
    otp: new FormControl('' , Validators.required),
    password: new FormControl('' , Validators.required),
  });


  ngOnInit(): void {
    this.showForgotPasswordForm = true;
  }
  forgot(value){
    console.log(value.control.value);
    this.mobile = value.control.value.mobile;
    let obj = { phone_number : value.control.value.mobile};
    this.cognito.forgotPassword(obj).then(res=>{
      this.showForgotPasswordForm = false;
      this.toastrService.success('OTP sent successfully')
    })
  }

  resetPassword(value){
    console.log(value.control.value);
    let obj = value.control.value;
    this.cognito.confirmPassword(obj.otp ,obj.password,this.mobile).then(res =>{
      console.log(res,'confirm password');
      //this.router.navigate(['/users']); 
      this.utilsService.navigateRoute(RoutingConstants.User, "Users", "root");
      this.toastrService.success('Password reset successfully ')
    }).catch(err => {
      this.toastrService.error (err)
    })
  }

  public showPassword(input: any): any {
    input.type = input.type === 'password' ?  'text' : 'password';
   }

}
