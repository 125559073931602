import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import 'hammerjs'; 


import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { AppComponent } from './app.component';
import { ToastrModule } from 'ngx-toastr';
import { CommonModule } from '@angular/common';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoginComponent } from './login/login.component';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { GraphQLModule } from './graphql/graphql.module';
import { HttpLinkModule } from 'apollo-angular-link-http';
import { HttpClientModule , HTTP_INTERCEPTORS } from '@angular/common/http';
import { CognitoServiceProvider } from './services/cognito-service';
import { TokenInterceptor } from './services/token.interceptor';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';


@NgModule({
  imports: [
    FormsModule,
    ModalModule.forRoot(),
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpModule,
    CommonModule,
    ToastrModule.forRoot(),
    ComponentsModule,
    RouterModule,
    TooltipModule.forRoot(),
    AppRoutingModule,
    NgxSpinnerModule,
    ApolloModule,
    GraphQLModule,
    HttpLinkModule,
    HttpClientModule,    
   ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    LoginComponent,
    ForgotPasswordComponent,
    
        
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    CognitoServiceProvider,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
