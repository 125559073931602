<!-- <ol class="breadcrumb" id="breadcrumb" style="   margin-bottom: 0 !important;
background-color: transparent !important;">
    <li class="breadcrumb-item">
        <a href="javascript:void(0)" [routerLink]="['/albums']"><i class="fa fa-home mr-2" ></i>Home</a>
    </li>
    <li *ngFor="let breadcrumb of breadcrumbs; let i = index;" class="breadcrumb-item">
        <a [hidden]="i == (breadcrumbs.length - 1)">{{breadcrumb.name}}</a>
        <span [hidden]="i != (breadcrumbs.length - 1)">
            <b>{{breadcrumb.name}}</b>
        </span>
    </li>
</ol> -->
<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a href="/albums"><i class="fa fa-home" aria-hidden="true"></i>
         Home</a></li>
      <li class="breadcrumb-item active" aria-current="page"  [innerHtml]="getTitle()" > </li>
    </ol>
  </nav>