import gql from 'graphql-tag';

export const GET_CITIES = gql`
    query city {
        city
        {
            id
            name
            country
        }
    }
    
`;

export const GET_CITY = gql`
    query city ($searchTerm: String!) {
        city(where: {name: {_ilike: $searchTerm}}, limit: 10, order_by: {name: asc}) {
            id
            name
            country
        }
    }
`;