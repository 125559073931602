import gql from 'graphql-tag';

export const GET_USER_BY_MOBILE = gql`
query user ($mobile: String!) {
  user (where: {mobile : {_eq : $mobile} }) {
      id
      name
      role
      city
        {
          id
          name
          country
        }
      mobile
      email
      country
      region {
        id
        name
      }
    }
  }
`;