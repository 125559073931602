<br><br><br>
<div class="body">
    <div class="container login-container">
        <div class="row">
            <div class="col-md-6">
                <img src="assets/img/icon.png" class=" img-fluid" alt="">
            </div>
            <div class="col-md-6 login-form-2">
                <h3>Login</h3>
                <div *ngIf="authError">
                    <div class="alert alert-danger">
                        {{ authError?.message }}
                    </div>
                </div>

                <form #loginForm="ngForm" (ngSubmit)="login(loginForm)">
                    <div class="form-group">
                        <!-- <input type="text" class="form-control" ngModel #mobile="ngModel" name="mobile" placeholder="Mobile Number *" 
                        autocomplete="off" />
                        <div class="input-group-append">
                            <span class="input-group-text" id="basic-addon2">    <a href="#" class="tip">   <i class="fa fa-question-circle-o mark" aria-hidden="true"></i><span>Enter your mobile number including Country code.</span></a></span>
                          </div> -->

                          <div class="input-group ">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon2">
                                  <a href="#" class="tip">   <i class="fa fa-question-circle-o" aria-hidden="true"></i><span>Enter your mobile number including Country code.</span></a>
                                </span>
                              </div>
                            <input type="text" class="form-control" ngModel #mobile="ngModel" name="mobile" data-aui-name="inpMobileNo" placeholder="Mobile Number *" 
                        autocomplete="off"/>
                          
                          </div>
                    </div>

                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon2">
                                <i class="fa fa-key" aria-hidden="true"></i>
                            </span>
                          </div>
                        <input type="password" class="form-control" ngModel #password="ngModel" name="password" placeholder="Your Password *" data-aui-name="inpPassword" />
                    </div>
                    
                    <div class="form-group text-center">                        
                        <button type="submit" class="btnSubmit" [disabled]="loginForm.invalid" data-aui-name="btnLogin">Login</button>
                        <div class="form-group">
    
                            <a href="/forgot-password" class="ForgetPwd" value="Login">Forgot Password?</a>
                        </div>
                    </div>
                   
                </form>
            </div>
        </div>
    </div>
</div>