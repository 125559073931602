import { NgModule } from '@angular/core';
import { Apollo, ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpHeaders, HttpClientModule } from '@angular/common/http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink, HttpLinkModule } from 'apollo-angular-link-http';
import { environment } from './../../environments/environment';
//const uri = 'https://swsdb.herokuapp.com/v1/graphql';
//const uri = 'https://d2pqbpy319ye9g.cloudfront.net/v1/graphql';
const uri = environment.graphql;//'https://api.satsangwithswamiji.com/v1/graphql';//"http://52.77.183.242//v1/graphql"; //
const jwt =  localStorage.getItem('jwt');
const authHeader = new HttpHeaders ()
.set('Authorization', `Bearer `+jwt)
.set('Content-Type', 'application/json');


export function createApollo(httpLink: HttpLink) {
  return {
    link: httpLink.create({uri, headers: authHeader}),
    cache: new InMemoryCache(),
  };
}

@NgModule({
  exports: [ApolloModule, HttpLinkModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})


export class GraphQLModule {

}

// export class GraphQlModule { 
//   constructor(apollo: Apollo, httpLink: HttpLink) {
//     const uri = 'https://swsdb.herokuapp.com/v1/graphql';
//     const authHeader = new HttpHeaders ()
//     //.set('X-Hasura-Access-Key', '')
//     .set('Content-Type', 'application/json')
//     .set('Authorization', `Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjBlYTNmN2EwMjQ4YmU0ZTBkZjAyYWVlZWIyMGIxZDJlMmI3ZjI0NzQiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vc3dzYXV0aCIsImF1ZCI6InN3c2F1dGgiLCJhdXRoX3RpbWUiOjE1ODI3ODk5NjIsInVzZXJfaWQiOiJudnE1cGU0QWxWTmx1V2lGa0NPOXZmU2xmTHQyIiwic3ViIjoibnZxNXBlNEFsVk5sdVdpRmtDTzl2ZlNsZkx0MiIsImlhdCI6MTU4Mjc4OTk2MiwiZXhwIjoxNTgyNzkzNTYyLCJlbWFpbCI6InN3c0BnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZW1haWwiOlsic3dzQGdtYWlsLmNvbSJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.GvfFjhTNa2tpctLE97s6GNRxcLBYFCDZNzgYlk5G6tfE8f2HeIOh0HIsGH1iiwqGO0Bw4KRWZbYmBiyZ1PySzHGXYcKvN5XGCC6d2weMr9qoX8oVp5itXDLUxYGgcerZv7L8TpmiW6kS9mj6siDypHwT90ak8-ZPMhuurkqhASX5Z7vWNl66xXsahgyw7efTy6usMC9IPB2a_FYQ1BcF1-7p0lAQsqsGgZzuygP4s74xcHKcVUsZzorJhXcNpUPkc30AeffuLMuUKH0-s5Pf2AVqYXrh-VnrCv5yvPWtb24diMAuYFmfZT4Sr6Uk07_g8Uh7bUVWTXjJy2zEZMP4EQ`);
//     // .set('X-Hasura-Role', 'user')
//     // .set('X-Hasura-User-Id', 'e8854292-b46a-45cc-b251-0bb7aeffd757');

   
//     //console.log("localStorage.getItem('user_id')="+ localStorage.getItem('user_id'));
//     const http = httpLink.create({ uri, headers: authHeader});

//     apollo.create({
//       link: http,
//       cache: new InMemoryCache()
//     });
//   }
// }
