// // The file contents for the current environment will overwrite these during build.
// // The build system defaults to the dev environment which uses `environment.ts`, but if you do
// // `ng build --env=prod` then `environment.prod.ts` will be used instead.
// // The list of which env maps to which file can be found in `.angular-cli.json`.

// // export const environment = {
// //   production: false,
// //   firebase: {
// //     apiKey: 'AIzaSyApNoKOGf3ECBzZ21Z-AmcvRo0neh4Oho0',
// //   authDomain: 'swsauth.firebaseapp.com',
// //   databaseURL: 'https://swsauth.firebaseio.com',
// //   projectId: 'swsauth',
// //   storageBucket: 'swsauth.appspot.com',
// //   messagingSenderId: '322810763846',
// //   appId: '1:322810763846:web:15fc9a5067e07f0a3f8847'
// //   },
// //   CognitoUserPoolId:"ap-southeast-1_Meg2DpBY1",
// //   CognitoClientId:"6bu834muk7bl5ku6a8gmhrtn2j",
// //   CognitoRegion:"ap-southeast-1",
// //   mediaUrl:"https://dev-media.satsangwithswamiji.com/",
// //   // lamda : "http://localhost:3000/",
// //   lamda : "https://devlamda.satsangwithswamiji.com/",
// //   graphql:'https://dev.satsangwithswamiji.com/v1/graphql',
// //   s3accessKeyId: 'AKIASZOHYUVXIOV74K5M',
// //   s3secretAccessKey: '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',
// //   s3region: 'ap-southeast-1',
// //   s3mediaBucket:"dev-sws-media",
// //   mode:"Test"
// // };

// export const environment = {
//   production: true,
//   firebase: {
//     apiKey: 'AIzaSyApNoKOGf3ECBzZ21Z-AmcvRo0neh4Oho0',
//     authDomain: 'swsauth.firebaseapp.com',
//     databaseURL: 'https://swsauth.firebaseio.com',
//     projectId: 'swsauth',
//     storageBucket: 'swsauth.appspot.com',
//     messagingSenderId: '322810763846',
//     appId: '1:322810763846:web:15fc9a5067e07f0a3f8847'
//   },
//   CognitoUserPoolId:"ap-southeast-1_DtlLys9aD",
//   CognitoClientId:"2vo2vklidh5v3n90pfjhu9acpu",
//   CognitoRegion:"ap-southeast-1",
//   mediaUrl:"https://swsmedia.b-cdn.net/",
//   lamda : "https://lamda.satsangwithswamiji.com/",
//   graphql:'https://api.satsangwithswamiji.com/v1/graphql',
//   s3accessKeyId: 'AKIASZOHYUVXGQQBXFPD',
//   s3secretAccessKey: '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',
//   s3region: 'ap-southeast-1',
//   s3mediaBucket:"sws-media",
//   mode:"Prodcution"
// };


export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyApNoKOGf3ECBzZ21Z-AmcvRo0neh4Oho0',
    authDomain: 'swsauth.firebaseapp.com',
    databaseURL: 'https://swsauth.firebaseio.com',
    projectId: 'swsauth',
    storageBucket: 'swsauth.appspot.com',
    messagingSenderId: '322810763846',
    appId: '1:322810763846:web:15fc9a5067e07f0a3f8847'
  },
  CognitoUserPoolId:"ap-southeast-1_DtlLys9aD",
  CognitoClientId:"2vo2vklidh5v3n90pfjhu9acpu",
  CognitoRegion:"ap-southeast-1",
  mediaUrl:"https://swsmedia.b-cdn.net/",
  lamda : "https://lamda.satsangwithswamiji.com/",
  graphql:'https://api.satsangwithswamiji.com/v1/graphql',
  s3accessKeyId: 'AKIASZOHYUVXGQQBXFPD',
  s3secretAccessKey: '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',
  s3region: 'ap-southeast-1',
  s3mediaBucket:"sws-media",
  mode:"Prodcution"
};
