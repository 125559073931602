import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { UserRegistrationService } from './user-registration.service';
import { SessionStorageService } from './session-storage.service';
import { Apollo } from 'apollo-angular';
import * as Query from './../graphql/queries/user/User';
import * as jwt_decode from "jwt-decode";
import { CognitoServiceProvider} from './cognito-service';
import { UtilsService } from './utils.service';
import { RoutingConstants } from '../constants/RoutingConstants';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private eventAuthError = new BehaviorSubject<string>('');
  eventAuthError$ = this.eventAuthError.asObservable();
  newUser: any;
  roleBased: any[]; 
  loginUser: any;
  loggedInUser: any;
  loggedIn: { role: any; name: any; id: any; };

  constructor(
    private router: Router,
    private utilService: UtilsService,
    private session: SessionStorageService,
    private apollo: Apollo,
    private cognito : CognitoServiceProvider
  ) { }

  getCurrentTimeISTSecond(){
    var currentTime = new Date();
    var currentOffset = currentTime.getTimezoneOffset();
    var ISTOffset = 330;   // IST offset UTC +5:30 
    var ISTTime = new Date(currentTime.getTime() + (ISTOffset + currentOffset)*60000);
    var currentIST = Math.floor(ISTTime.getTime()/1000);
    return currentIST+600;
}
  getCurrentTime(): Date{
    let d = new Date();
    let utc = d.getTime() + (d.getTimezoneOffset() * 60000);
    let nd = new Date(utc + (3600000*+5.5));
    var ist =  nd.toLocaleString();
    return nd;
  }

  async getCurrentToken() {
    const token: string = localStorage.getItem('jwt')
    // Get Current UTC time and remove milliseconds added 10 minitues ..so token refreshed before 10 minitues
    const now : any =  Math.round(( new Date().valueOf() / 1000) + 150);
    if (token) {
      const decoded = this.cognito.decodeToken(token);
      if (decoded.exp === undefined) {
        // console.log('i m triggered ');
        this.logout();
        return null;

      }
      var exp = decoded.exp * 1000;
      // console.log('UTC check the times both UTC Format');
      // console.log(decoded.exp  + ' UTC >= ' + now );
      if (decoded.exp >= now) {
        // console.log('old token');
        return token;
      } else {

        // console.log('new token');
        return   this.cognito.refreshToken();
      }
    } else {

      return false;
    }
  }
  setLocalStorage(key,value){
    localStorage.setItem(key,value);
  }
  getLocalStorage(key){
    let val = localStorage.getItem(key);
    return val;
  }

  // Logout the user remove local, session storage
  logout() {
      // Remove loginUser from session storage
      sessionStorage.removeItem('loginUser');
      
      // Remove data from local storage
      localStorage.removeItem('jwt');
      localStorage.removeItem('jwt-exp');
      localStorage.removeItem('jwt-authTime');
      localStorage.removeItem('refreshToken');
      localStorage.clear();
      // Navigate to login page
      //this.router.navigate(['login']);
      this.utilService.navigateRoute(RoutingConstants.Login, "Login", "root");
  }


}
