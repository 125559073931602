import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, ActivatedRouteSnapshot, UrlSegment, NavigationEnd } from '@angular/router';
import { UtilsService } from '../../services/utils.service';

@Component({
    selector: 'app-breadcrumb',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent {
    public pageTitle: string;
    public breadcrumbs: { name: string; url: string }[] = [];

    constructor(
        public router: Router,
        public activatedRoute: ActivatedRoute,
        private utilsService: UtilsService) {
        this.router.events.subscribe(event => {
           if (event instanceof NavigationEnd) {
              this.breadcrumbs = [];
              //this.parseRoute(this.router.routerState.snapshot.root);
           }
        })
    }

    getTitle() {

        let routeMap = this.utilsService.getRoutingMap();
        let finalRoute :any = [];
        let breadCrumb = '';
        if (routeMap !== null) {
          routeMap.forEach( (route, index) => {
              if (routeMap.length-1 !== index) {
                  finalRoute.push(`<a href="`+route.url+`">` +route.name+ `</a>`);
              } else {
                  finalRoute.push(route.name);
              }
          });
    
          breadCrumb =  finalRoute.join('   /   ');
        }
        return breadCrumb;
    
      }

    // parseRoute(node: ActivatedRouteSnapshot) {
    //     if (node.data['breadcrumb']) {
    //         if (node.url.length) {
    //             let urlSegments: UrlSegment[] = [];
    //             node.pathFromRoot.forEach(routerState => {
    //                 urlSegments = urlSegments.concat(routerState.url);
    //             });
    //             const url = urlSegments.map(urlSegment => {
    //                 //console.log("In breadcrumb = "+urlSegment.path);
    //                 return urlSegment.path;
    //             }).join('/');
    //             this.breadcrumbs.push({
    //                 name: node.data['breadcrumb'],
    //                 url: '/' + url
    //             })
    //         }
    //     }
    //     if (node.firstChild) {
    //         this.parseRoute(node.firstChild);
    //     }
    // }

}