export class RoutingConstants {
    public static Login = 'login';
    
    // Album Routing
    public static Album = 'albums';
    public static AlbumAdd = 'albums/:mode/:albumId';
    public static AlbumEdit = 'albums/:mode/:albumId'; 
    
    //Album routing URL
    public static AlbumAddUrl = (mode, albumId) => `albums/${mode}/${albumId}`;
    public static AlbumEditUrl = (mode, albumId) => `albums/${mode}/${albumId}`;

    //Add track to album
    public static AudioAdd = 'albums/:albumId';

    //Add track to album routing URL
    public static AudioAddAddUrl = (albumId) => `albums/${albumId}`;

    // Tags Routing
    public static Tag = 'tags';
    public static TagAdd = 'tags/:mode/:tagId';
    public static TagEdit = 'tags/:mode/:tagId'; 
    public static TagOrder = 'tags/tags-order';

    //Tags routing URL
    public static TagAddUrl = (mode, tagId) => `tags/${mode}/${tagId}`;
    public static TagEditUrl = (mode, tagId) => `tags/${mode}/${tagId}`;
    
    // Books routing
    public static Book = 'books';
    public static BookAdd = 'books/:mode/:bookId';
    public static BookEdit = 'books/:mode/:bookId';

    //Books routing URL
    public static BookAddUrl = (mode, bookId) => `books/${mode}/${bookId}`;
    public static BookEditUrl = (mode, bookId) => `books/${mode}/${bookId}`;

    // Tracks routing
    public static Track = 'tracks';
    public static TrackAdd = 'tracks/:mode/:trackId';
    public static TrackEdit = 'tracks/:mode/:trackId';
    
    //Tracks routing URL
    public static TrackAddUrl = (mode, trackId) => `tracks/${mode}/${trackId}`;
    public static TrackEditUrl = (mode, trackId) => `tracks/${mode}/${trackId}`;

    // Users routing
    public static User = 'users';
    public static UserAdd = 'users/:mode/:userId';
    public static UserEdit = 'users/:userId';

    //Tracks routing URL
    public static UserAddUrl = (mode, userId) => `users/${mode}/${userId}`;
    public static UserEditUrl = (userId) => `users/${userId}`;

    // Region routing
    public static Region = 'region';
    public static RegionAdd = 'region/:mode/:regionId';
    public static RegionEdit = 'region/:regionId';

    //Region routing URL
    public static RegionAddUrl = (mode, regionId) => `region/${mode}/${regionId}`;
    public static RegionEditUrl = (mode, regionId) => `region/${mode}/${regionId}`;

    // Upload routing
    public static Upload = 'upload';

    // Notification routing
    public static Notification = 'notification'; 

    // Device routing
    public static Device = 'device';

    public static Log = 'log';
    

 }
